import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import config from '../config'
import * as storage from '../utils/storage'
// import dingtalk from './dingtalk'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
      showbar: false
    },
    children: [
      // ...dingtalk, // 自定义审批
     
    ]
  },
 
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      title: '登录',
      anonymous: true,
      showbar:false

    },
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/customer.vue'),
    meta: {
      title: '客户',
      anonymous: true,
      showbar:false

    },
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/order.vue'),
    meta: {
      title: '新增订单',
      anonymous: true,
      showbar:false

    },
  },

  {
    path: '/transferRecords',
    name: 'transferRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/transferRecords.vue'),
    meta: {
      title: '新增充值记录',
      anonymous: true,
      showbar:false

    },
  },

  {
    path: '/deductRecords',
    name: 'deductRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/deductRecords.vue'),
    meta: {
      title: '新增抵扣记录',
      anonymous: true,
      showbar:false

    },
  },





  

  {
    path: '/addFriendsNum',
    name: 'addFriendsNum',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/addFriendsNum.vue'),
    meta: {
      title: '新增好友数量',
      anonymous: true,
      showbar:false

    },
  },



  {
    path: '/safeIp',
    name: 'safeIp',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/safeIp.vue'),
    meta: {
      title: '新增安全ip',
      anonymous: true,
      showbar:false

    },
  },

  {
    path: '/receiptsRecords',
    name: 'receiptsRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/receiptsRecords.vue'),
    meta: {
      title: '新增订单收款记录',
      anonymous: true,
      showbar:false

    },
  },
  

  
  {
    path: '/transferList',
    name: 'transferList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/transferList.vue'),
    meta: {
      title: '充值记录列表',
      anonymous: true,
      showbar:false

    },
  },


  {
    path: '/assignOutOrderList',
    name: 'assignOutOrderList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/assignOutOrderList.vue'),
    meta: {
      title: '已分配外部订单',
      anonymous: true,
      showbar:false

    },
  },


  
  {
    path: '/poolCustomerList',
    name: 'poolCustomerList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/poolCustomerList.vue'),
    meta: {
      title: '客户池',
      anonymous: true,
      showbar:false

    },
  },

  
  {
    path: '/consultStaticAdd',
    name: 'consultStaticAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/consultStaticAdd.vue'),
    meta: {
      title: '咨询统计',
      anonymous: true,
      showbar:false

    },
  },

  {
    path: '/customerTagList',
    name: 'customerTagList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/customerTagList.vue'),
    meta: {
      title: '客户标签列表',
      anonymous: true,
      showbar:false

    },
  },


  {
    path: '/tagCustomerList',
    name: 'tagCustomerList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/tagCustomerList.vue'),
    meta: {
      title: '标签客户列表',
      anonymous: true,
      showbar:false

    },
  },


  
  {
    path: '/customerOrderList',
    name: 'customerOrderList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/customerOrderList.vue'),
    meta: {
      title: '客户订单列表',
      anonymous: true,
      showbar:false

    },
  },
  
  {
    path: '/sellerStaticList',
    name: 'sellerStaticList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/sellerStaticList.vue'),
    meta: {
      title: '销售统计',
      anonymous: true,
      showbar:false

    },
  },



  {
    path: '/achievementStaticList',
    name: 'achievementStaticList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/achievementStaticList.vue'),
    meta: {
      title: '业绩统计',
      anonymous: true,
      showbar:false

    },
  },




  {
    path: '/consultStaticList',
    name: 'consultStaticList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/consultStaticList.vue'),
    meta: {
      title: '咨询统计列表',
      anonymous: true,
      showbar:false

    },
  },

  
  
  {
    path: '/sellerTaskList',
    name: 'sellerTaskList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/sellerTaskList.vue'),
    meta: {
      title: '销售任务列表',
      anonymous: true,
      showbar:false

    },
  },


  {
    path: '/sellerTaskAdd',
    name: 'sellerTaskAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/sellerTaskAdd.vue'),
    meta: {
      title: '销售任务',
      anonymous: true,
      showbar:false

    },
  },



  {
    path: '/adUserRela',
    name: 'adUserRela',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/adUserRela.vue'),
    meta: {
      title: '广告人员关联',
      anonymous: true,
      showbar:false

    },
  },

  
  {
    path: '/customerAssignView',
    name: 'customerAssignView',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/customerAssignView.vue'),
    meta: {
      title: '客户分配详情',
      anonymous: true,
      showbar:false

    },
  },
    
  {
    path: '/poolCustomerAdd',
    name: 'poolCustomerAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/poolCustomerAdd.vue'),
    meta: {
      title: '客户池新增客户',
      anonymous: true,
      showbar:false

    },
  },


  
  {
    path: '/customerAssignList',
    name: 'customerAssignList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/customerAssignList.vue'),
    meta: {
      title: '客户分配记录',
      anonymous: true,
      showbar:false

    },
  },


  {
    path: '/receiptsList',
    name: 'receiptsList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/receiptsList.vue'),
    meta: {
      title: '订单收款记录列表',
      anonymous: true,
      showbar:false

    },
  },


  {
    path: '/deductList',
    name: 'deductList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/deductList.vue'),
    meta: {
      title: '抵扣记录列表',
      anonymous: true,
      showbar:false

    },
  },

  

  {
    path: '/customerView',
    name: 'customerView',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/customerView.vue'),
    meta: {
      title: '客户详情',
      anonymous: true,
      showbar:false

    },
  },


  {
    path: '/orderView',
    name: 'orderView',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/orderView.vue'),
    meta: {
      title: '订单详情',
      anonymous: true,
      showbar:false

    },
  },


  {
    path: '/outOrderView',
    name: 'outOrderView',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/outOrderView.vue'),
    meta: {
      title: '外部订单详情',
      anonymous: true,
      showbar:false

    },
  },


  {
    path: '/orderList',
    name: 'orderList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/orderList.vue'),
    meta: {
      title: '订单列表',
      anonymous: true,
      showbar:false

    },
  },
  {
    path: '/customerList',
    name: 'customerList',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/customerList.vue'),
    meta: {
      title: '客户列表',
      anonymous: true,
      showbar:false

    },
  },

  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/mine.vue'),
    meta: {
      title: '个人中心',
      anonymous: true,
      showbar:false

    },
  },

  
  {
    path: '/returnExchange',
    name: 'returnExchange',
    component: () => import(/* webpackChunkName: "about" */ '../views/work/returnExchange.vue'),
    meta: {
      title: '新增退换货审批',
      anonymous: true,
      showbar:false

    },
  },

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  
  const userKey = storage.getString(config.userKey)
  
  if (userKey || to.meta.anonymous) {
    next()
    return
  } else {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    })
  }
})



export default router
