<template>
  <div>
    <div v-for="item in fileList" :key="item.id">
      <van-tag
        closeable
        size="medium"
        type="primary"
        @close="beforeDelete(item)"
      >
        <a>
          {{ item.name }}
        </a>
      </van-tag>
    </div>

    <van-uploader
      :preview-image="false"
      :max-count="limit"
      :disabled="disabled"
      :accept="accept"
      :after-read="afterRead"
      multiple
    >
      <van-button icon="plus" type="primary">上传文件</van-button>
    </van-uploader>
  </div>
</template>

<script>
import { Uploader as vanUploader } from "vant";
import { accessory } from "@/services/library";
export default {
  components: { vanUploader },
  props: {
    resourceId: String,
    displayType: String,
    mode: String,
    limit: Number,
    accept: {
      type: String,
      default: "*",
    },
    resourceTag: String,
    disabled: Boolean,
  },
  data() {
    return {
      fileList: [],
      accessorySaveVo: {
        originalFiles: [], // {id,name}
        addedFiles: [], // {uid,name,status:[ready,uploading,success,fail]}
        removedFiles: [], // {accessorySaveVo.originalFiles:[id],accessorySaveVo.addedFiles:[id],accessorySaveVo.removedFiles:[id]}
      },
    };
  },
  computed: {},
  async created() {
    await this.loadFiles();
  },
  methods: {
    async loadFiles() {
      this.fileList = await accessory.getList(
        this.resourceId,
        this.resourceTag
      );
      this.fileList = this.fileList.map((file) => {
        return {
          name: file.fileName,
          id: file.accessoryId,
          url: file.directlyUrl,
        };
      });
      this.accessorySaveVo.originalFiles = this.fileList.map((item) => item.id);
    },
    beforeDelete(file) {
      if (this.accessorySaveVo.originalFiles.indexOf(file.id) > -1) {
        this.accessorySaveVo.removedFiles.push(file.id);
      }
      if (this.accessorySaveVo.addedFiles.indexOf(file.id) > -1) {
        this.accessorySaveVo.addedFiles.splice(
          this.accessorySaveVo.addedFiles.indexOf(file.id),
          1
        );
      }
      this.fileList.splice(this.fileList.indexOf(file), 1);
    },
    async afterRead(file) {
      const uploadData = {
        resourceId: this.resourceId,
        resourceTag: this.resourceTag,
        fileId: "",
        file: file.file,
      };
      var res = { result: "11111" };
      this.fileList.push({
        name: file.file.name,
        id: res.result,
        url: "",
      });
      this.accessorySaveVo.addedFiles.push(res.result);
      // var res = await accessory.uploadTempFile(uploadData);
      // console.log(res);
      // if (res.status) {
      //   file.status = "";
      //   file.message = "";
      //   file.fileid = res.result;
      // } else {
      //   file.status = "failed";
      //   file.message = "上传失败";
      // }
    },
    async saveFileList() {
      console.log(this.accessorySaveVo);
      // await accessory.saveFileList(this.accessorySaveVo);
    },
  },
};
</script>

<style scoped>
.file-upload {
  min-height: 32px;
}

.el-tag + .el-tag {
  margin-left: 5px;
}
.button-new-tag {
  margin-right: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.tag-percent {
  display: inline-block;
  margin-left: -10px;
  margin-right: -5px;
  width: 100%;
  height: 100%;
}
.tag-percent-bg {
  width: 0%;
  height: 100%;
  background-color: rgba(103, 194, 58, 0.2);
  padding: 0 5px 0 10px;
}
</style>
