export const setString = (key, val) => {
  sessionStorage.setItem(key, val)
}

export const setObject = (key, val) => {
  sessionStorage.setItem(key, JSON.stringify(val))
}

export const getString = key => {
  return sessionStorage.getItem(key)
}

export const getObject = key => {
  return JSON.parse(sessionStorage.getItem(key))
}

export const remove = key => {
  return sessionStorage.removeItem(key)
}
