const config = {
  version: 0.1,
  siteName: '香至尊crm',
  userKey: 'current-user-mobile',
  tokenKey: 'auth-token-mobile',
  apiUrl: process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:8080/',
  pageSize: 10,
  authenticationUrl: process.env.NODE_ENV === 'production' ? 'http://49.4.1.82:9080/sso/passport': 'http://49.4.1.82:9080/sso/passport', // 测试
  logoutUrl: process.env.NODE_ENV === 'production' ? 'http://49.4.1.82:9080/LogoutSSO.aspx' : 'http://49.4.1.82:9080/LogoutSSO.aspx' // 测试
}

export default config

