import Axios from 'axios'
import config from '@/config'
import * as storage from '@/utils/storage'

export default function (data, filename, mime, bom) {
  var blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  var blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var blobURL = window.URL.createObjectURL(blob)
    var tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}
export const downloadFilePost = ({ fileUrl, data, config, fileName }) => {
  fileService.post(fileUrl, data, config).then(res => {
    let blob = res.data
    if (!fileName) {
      let disposition = res.headers['content-disposition']
      if (disposition) {
        fileName = res.headers['content-disposition'].split(';')[1].split('filename=')[1]
        let fileNameUnicode = res.headers['content-disposition'].split('filename*=')[1]
        if (fileNameUnicode) {
          // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
          fileName = decodeURIComponent(fileNameUnicode.split("''")[1])
        }
      } else fileName = 'undefine'
    }
    if ('download' in document.createElement('a')) {
      // 非IE下载
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.download = fileName
      elink.href = window.URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    } else {
      // IE10+下载
      navigator.msSaveBlob(blob, fileName)
    }
  })
}
export const fileService = Axios.create({
  baseURL: config.apiUrl,
  responseType: 'blob',
  timeout: 100000
})

fileService.interceptors.request.use(
  req => {
    const token = storage.getString(config.tokenKey)
    if (token) {
      req.headers.Authorization = `bearer ${token}`
    }

    return req
  },
  err => Promise.reject(err)
)
