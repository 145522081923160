<template>
  <div id="app">
    <van-nav-bar
      v-if="$route.meta.showbar"
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
  <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive> -->
    <router-view></router-view>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: "App",

  methods: {
    async onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
