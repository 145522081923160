import config from '@/config'
import http from '@/utils/http'
import download from '@/utils/download'

const contextPath = 'common/accessory'

export async function deleteTempFile (resourceId, fileId) {
  const res = await http.delete(`${contextPath}/deleteTempFile`, {
    params: { resourceId, fileId }
  })
  return res.status ? res.result : []
}

export async function deleteTempFileList (resourceId, resourceTag) {
  const res = await http.delete(`${contextPath}/deleteTempFileList`, {
    params: { resourceId, resourceTag }
  })
  return res.status ? res.result : []
}

export async function deleteFile (accessoryId) {
  const res = await http.delete(`${contextPath}/deleteFile`, {
    params: { accessoryId }
  })
  return res.status ? res.result : []
}

export async function downloadFile (accessoryId, fileName) {
  const res = await http.get(`${contextPath}/downloadFile`, {
    params: {
      accessoryId
    },
    responseType: 'arraybuffer'
  })

  if (res.status) {
    download(res.result, fileName, 'application/octet-stream')
  }
}

export function uploadUrl () {
  return `${config.apiUrl}${contextPath}/uploadTempFile`
}
export function downloadUrl (accessoryId) {
  return `${config.apiUrl}${contextPath}/downloadFile?accessoryId=` + accessoryId
}
export function uploadSealSignatureUrl () {
  return `${config.apiUrl}dingtalk/formpredefine/SealSignature/uploadSealSignature`
}
export function signatureUploadTempFile () {
  return `${config.apiUrl}${contextPath}/signatureUploadTempFile`
}

export async function getList (resourceId, resourceTag) {
  const res = await http.get(`${contextPath}/getList`, {
    params: { resourceId, resourceTag }
  })

  return res.status ? res.result : []
}

export async function getTempList (resourceId, resourceTag) {
  const res = await http.get(`${contextPath}/getTempList`, {
    params: { resourceId, resourceTag }
  })

  return res.status ? res.result : []
}

// export async function getList (resourceId, resourceTag) {
//   const res = await http.get(`${contextPath}/getList`, {
//     params: { resourceId, resourceTag }
//   })

//   return res.status ? res.result : []
// }

export async function saveFileList (accessorySaveVo) {
  const res = await http.put(`${contextPath}/saveFileList`, accessorySaveVo)
  return res.status ? res.result : []
}

export async function saveSignatureFileList (accessorySaveVo) {
  const res = await http.get(`${contextPath}/saveSignatureFileList`, {params: { ...accessorySaveVo }})
  return res.status ? res.result : []
}


