import Axios from 'axios'
import { Notify } from 'vant';
import config from '../config'
import * as storage from './storage'


Axios.defaults.withCredentials=true

const http = Axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
  timeout: 50000
})




http.interceptors.request.use(
  req => {
    const token = storage.getString(config.tokenKey)

    if(req.url.indexOf("saveExchange")!=-1){
      req.headers.transformRequest=[function(data, headers) {
            // 去除post请求默认的Content-Type
            delete headers.post['Content-Type']
            return data
          }];
      req.headers={'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()};
    }
    if (token) {
      req.headers.Authorization = `bearer ${token}`
    }

    return req
  },
  err => {
    Notify({ message: err.message, type: 'danger', duration: 2000 })

    return Promise.reject(err)
  }
)

http.interceptors.response.use(
  res => {
    if (res.data) {
      const headers = res.headers

      if (headers['content-type'] === 'application/octet-stream') {
        return {
          status: true,
          message: null,
          result: res.data
        }
      }

      if (!res.data.status && res.data.message) {
         Notify({ message: res.data.message, type: 'danger', duration: 2000 })
      }

      return res.data
    } else {
      return {
        status: false,
        message: null,
        result: null
      }
    }
  },
  err => {
    
    debugger
    const res = err.response 
    let msg = ''
    if (res && res.data) {
      msg =  res.data.exceptionMessage
      
    }else{
      msg="网络错误"
      // ywauth.quit()
      // storage.remove(config.tokenKey)
      // storage.remove(config.userKey)
      // storage.remove(config.accountKey)
      // storage.remove(config.elementKey)
      // storage.remove(config.menusAllKey)
      // storage.remove(config.menusKey)
      // window.location.reload()

    }
   Notify({ message: msg, type: 'danger', duration: 2000 })
    return {
      status: false,
      message: msg,
      result: null
    }
  }
)

export default http
