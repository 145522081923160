import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import '@/assets/styles/index.less' // global css
import dayjs from 'dayjs'
import config from './config'
//Vant框架
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import {
  Toast
} from 'vant';



import qs from 'qs';

import cookies from 'js-cookie';
Vue.prototype.$cookies = cookies

import { TreeSelect } from 'vant';

Vue.use(TreeSelect);

Vue.use(Toast);
import {
  Notify,DatetimePicker 
} from 'vant';
Vue.use(Notify);
Vue.use(DatetimePicker );

import md5 from 'js-md5'
Vue.prototype.$md5 = md5
Vue.prototype.$qs = qs
Vue.prototype.$dayjs = dayjs
Vue.prototype.$config = config
Vue.config.productionTip = false
// 时间格式化
Vue.filter('dateformat', function (value, format = 'YYYY-MM-DD') {
  if (!value) return ''
  return dayjs(value).format(format)
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
