<template>
  <div class="home">
    <!-- 首页 -->
    <!-- <van-button type="primary" @click="push">选择人员</van-button> receipts  抵扣记录  -->
    <br />
    <van-tag type="primary"    style="margin-top: 6%;margin-bottom: 6%;height: 0.8rem;font-size: 0.6rem;" >香至尊crm系统</van-tag>
    <div v-if="user.deptId=='1'">
      <van-divider  :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"> </van-divider>
      <van-grid :column-num="3" >
        <van-grid-item  icon="friends-o" text="新增客户"  @click="goPageCustomer"/>
        <van-grid-item  icon="friends-o" text="客户列表"   @click="goPageCustomerList"/>
        <van-grid-item  icon="orders-o" text="新增订单"   @click="goPageOrder"/>
        <van-grid-item  icon="orders-o" text="订单列表"   @click="goPageOrderList"/>
        <van-grid-item  icon="orders-o" text="已分配外部订单"   @click="goPageAssignOutOrderList"/>
      </van-grid>
      <van-divider  :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"> </van-divider>
      <van-grid :column-num="3" >

        <van-grid-item  icon="notes-o" text="新增充值记录"   @click="goPageTransferRecords"/>
        <van-grid-item  icon="notes-o" text="充值记录列表"   @click="goPageTransferList"/>
        <van-grid-item  icon="notes-o" text="新增抵扣记录"   @click="goPageDeductRecords"/>
        <van-grid-item  icon="notes-o" text="抵扣记录列表"   @click="goPageDeductList"/>
        <van-grid-item  icon="notes-o" text="新增订单收款记录"   @click="goPageReceiptsRecords"/>
        <van-grid-item  icon="notes-o" text="订单收款记录列表"   @click="goPageReceiptsList"/>

      </van-grid>
      <van-divider  :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"> </van-divider>
      <van-grid :column-num="3" >

        
        <van-grid-item  icon="photo-o" text="新增好友数量"   @click="goAddFriendsNum"/>
        <van-grid-item  icon="photo-o" text="销售任务列表"   @click="goPageSellerTaskList"/>

        <van-grid-item  icon="photo-o" text="新增销售任务"   @click="goSellerTaskAdd"/>

        <van-grid-item  icon="cashier-o" text="咨询统计列表"   @click="goPageConsultStaticList"/>
        <van-grid-item  icon="cashier-o" text="新增咨询统计"   @click="goConsultStaticAdd"/>

        <van-grid-item  icon="cashier-o" text="销售统计"   @click="goSellerStatic"/>
        <van-grid-item  icon="cashier-o" text="业绩统计"   @click="goAchieveStatic"/>

        <van-grid-item  icon="flag-o" text="客户标签列表"   @click="goPageCustomerTagList"/>


      </van-grid>
      <van-divider  :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"> </van-divider>
      <van-grid :column-num="3" >
        <van-grid-item  icon="todo-list-o" text="客户池"   @click="goPagePoolCustomerList"/>
        <van-grid-item  icon="todo-list-o" text="客户分配记录"   @click="goPagePoolCustomerAssignList"/>
        <van-grid-item  icon="todo-list-o" text="客户池新增客户"   @click="goPoolCustomerAdd"/>
        <van-grid-item  icon="setting-o" text="广告人员关联"   @click="goAdUserRela"/>
        <van-grid-item  icon="setting-o" text="新增安全ip"   @click="goSafeIp"/>
        <van-grid-item  icon="user-o" text="个人中心"  @click="goPageMine"/>

      </van-grid>

    </div>

    <van-grid :column-num="2" v-if="user.deptId=='17'">
      <van-grid-item  icon="photo-o" text="新增充值记录"   @click="goPageTransferRecords"/>
      <van-grid-item  icon="photo-o" text="充值记录列表"   @click="goPageTransferList"/>
      <van-grid-item  icon="photo-o" text="新增抵扣记录"   @click="goPageDeductRecords"/>
      <van-grid-item  icon="photo-o" text="抵扣记录列表"   @click="goPageDeductList"/>

      <van-grid-item  icon="photo-o" text="新增订单收款记录"   @click="goPageReceiptsRecords"/>
      <van-grid-item  icon="photo-o" text="订单收款记录列表"   @click="goPageReceiptsList"/>


      <van-grid-item  icon="photo-o" text="客户列表"   @click="goPageCustomerList"/>
      <van-grid-item  icon="photo-o" text="订单列表"   @click="goPageOrderList"/>
      <van-grid-item  icon="photo-o" text="个人中心"  @click="goPageMine"/>
    </van-grid>

    <van-grid :column-num="2"  v-if="user.deptId=='29'">
      <van-grid-item  icon="photo-o" text="新增好友数量"   @click="goAddFriendsNum"/>
      <van-grid-item  icon="photo-o" text="客户池"   @click="goPagePoolCustomerList"/>
      <van-grid-item  icon="photo-o" text="客户池新增客户"   @click="goPoolCustomerAdd"/>
      <van-grid-item  icon="photo-o" text="个人中心"  @click="goPageMine"/>
    </van-grid>

    <van-grid :column-num="2" v-if="user.deptId!='1'&&user.deptId!='17'&&user.deptId!='29'">
      <van-grid-item  icon="photo-o" text="新增客户"  @click="goPageCustomer"/>
      <van-grid-item  icon="photo-o" text="新增订单"   @click="goPageOrder"/>
      <van-grid-item  icon="photo-o" text="客户列表"   @click="goPageCustomerList"/>
      <van-grid-item  icon="photo-o" text="订单列表"   @click="goPageOrderList"/>
      <van-grid-item  icon="photo-o" text="客户分配记录"   @click="goPagePoolCustomerAssignList"/>
      <van-grid-item  icon="photo-o" text="已分配外部订单"   @click="goPageAssignOutOrderList"/>
      <van-grid-item  icon="photo-o" text="个人中心"  @click="goPageMine"/>
    </van-grid>

    <br />
    <!-- <UploadControl
      :resourceId="'resourceId'"
      :resourceTag="'resourceTag'"
    ></UploadControl> -->


  </div>
</template>

<script>
import UploadControl from "@/components/UploadControl";
import * as userservice from "@/services/library/user.js";
import eventBus from "@/utils/eventBus.js";
import * as storage from "@/utils/storage";

// @ is an alias to /src
export default {
  name: "Home",
  components: {
    UploadControl,
  },
  data() {
    return {
      checkList: [],
      user:{
        id:"",
        name:"",
        account:"",
        password:"",
        entryTime:"",
        leaveTime:null,
        createTime:"",
        type:0,
        deptId:0,
        roleId:0,
        state:false,
        email:"",
        phone:"",
        address:"",
        sex:"",
      },
    };
  },
  mounted() {
    this.user= storage.getObject(this.$config.userKey);
  },
  methods: {
    async push() {
      
      console.log(this.checkList)
      this.$router.push({ path: "/UserSelector" });
    },

    async goPageReturnExchange() {
      
      // this.$router.push({ path: "/returnExchange" });
    },
    async goPageCustomer() {
      
      this.$router.push({ path: "/customer" });
    },

    async goPageOrder() {
      
      this.$router.push({ path: "/order" });
    },


    goAddFriendsNum() {
      
      this.$router.push({ path: "/addFriendsNum" });
    },



    goPageReceiptsRecords() {
      
      this.$router.push({ path: "/receiptsRecords" });
    },

    goPageDeductRecords() {
      
      this.$router.push({ path: "/deductRecords" });
    },

    goPageTransferRecords() {
      
      this.$router.push({ path: "/transferRecords" });
    },

    goPageReceiptsList() {
      
      this.$router.push({ path: "/receiptsList" });
    },


    goPageDeductList() {
      
      this.$router.push({ path: "/deductList" });
    },

    goPageTransferList() {
      
      this.$router.push({ path: "/transferList" });
    },
    

    async goPageOrderView() {
      
      this.$router.push({ path: "/orderView" });
    },
    
    async goPageOrderList() {
      
      this.$router.push({ path: "/orderList" });
    },
    
    async goPageCustomerList() {
      
      this.$router.push({ path: "/customerList" });
    },

    goSafeIp() {
      
      this.$router.push({ path: "/safeIp" });
    },



    
    async goSellerStatic() {
      
      this.$router.push({ path: "/sellerStaticList" });
    },


    goAchieveStatic() {
      
      this.$router.push({ path: "/achievementStaticList" });
    },



    async goPageConsultStaticList() {
      
      this.$router.push({ path: "/consultStaticList" });
    },


    goPageCustomerTagList() {
      
      this.$router.push({ path: "/customerTagList" });
    },




    async goPageSellerTaskList() {
      
      this.$router.push({ path: "/sellerTaskList" });
    },


    async goConsultStaticAdd() {
      
      this.$router.push({ path: "/consultStaticAdd" });
    },
    
    async goSellerTaskAdd() {
      
      this.$router.push({ path: "/sellerTaskAdd" });
    },

    async goPageAssignOutOrderList() {
      
      this.$router.push({ path: "/assignOutOrderList" });
    },


    async goPagePoolCustomerList() {
      
      this.$router.push({ path: "/poolCustomerList" });
    },


    async goPagePoolCustomerAssignList() {
      
      this.$router.push({ path: "/customerAssignList" });
    },


    async goAdUserRela() {
      
      this.$router.push({ path: "/adUserRela" });
    },


    
    async goPoolCustomerAdd() {
      
      this.$router.push({ path: "/poolCustomerAdd" });
    },


    
    

    async goPageMine() {
      
      this.$router.push({ path: "/mine" });
    },
  },
};
</script>
